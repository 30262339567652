<template>
    <div>
        <div class="w-100 pl-2 pr-2">
            <Breadcrumb :breadcrumb="breadCrumbItems"/>
        </div>
        <div class="main p-2">
            <Switcher />
        </div>
        <MetaTags 
            title="相談リスト｜ResMom相談online"
            description="リセマム相談オンラインの相談リストです。｜リセマム相談onlineは、日本最大級の教育情報Webメディア「リセマム」が提供する教育相談サービスです。幼児教育、小学校受験、中学受験、高校受験、大学受験、海外進学、国際教育、グローバル教育、進路相談、進路コンサル、留学、子育て相談や悩みについて専門家や先輩保護者などに「すぐに」「気軽に」「どこでも」相談いただけます。"
        />
    </div>
</template>
<script>
    import Switcher from './components/switcher.vue'
    export default {
        name: "lesson",
        data() {
            return {
                breadCrumbItems : [
                    {
                        title : this.$t("menu.breadcrumb.my_page"),
                        link : "/my-page"
                    },
                    {
                        title : this.$t("menu.breadcrumb.lesson_list"),
                        link : "#"
                    }
                ]
            }
        },
        components: {
            Switcher
        },
    }
</script>